<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>会员管理</el-breadcrumb-item>
        <el-breadcrumb-item>会员列表</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入用户名查询" v-model="queryInfo.query">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getUserList"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加会员</el-button
            >
          </el-col>
        </el-row>

        <!-- 用户列表区域 -->
        <el-table :data="this.userlist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="用户名" prop="username"></el-table-column>
          <el-table-column label="性别" prop="sex"></el-table-column>
          <el-table-column label="昵称" prop="nickname"></el-table-column>
          <el-table-column label="邮箱" prop="email"></el-table-column>
          <el-table-column label="真实姓名" prop="realname"></el-table-column>
          <el-table-column label="手机号" prop="phone"></el-table-column>
          <el-table-column label="城市" prop="address"></el-table-column>
          <el-table-column label="地址" prop="address2"></el-table-column>
          <el-table-column label="钱包金额" prop="money"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <!-- 修改按钮 -->
              <el-button
                class="button"
                type="primary"
                icon="el-icon-edit"
                size="big"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
              <!-- 删除按钮 -->
              <el-button
                class="button"
                type="danger"
                icon="el-icon-delete"
                size="big"
                @click="removeUserById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-card>
      <!-- 添加用户 -->
      <el-dialog
        title="添加会员"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addFormRef"
          label-width="70px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="addForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改用户的对话框 -->
      <el-dialog
        title="修改用户"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form
          :model="editForm"
          :rules="editFormRules"
          ref="editFormRef"
          label-width="80px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="editForm.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="editForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="editForm.sex" placeholder="请选择性别">
              <el-option
                v-for="item in sexOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editForm.email"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realname">
            <el-input v-model="editForm.realname"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="editForm.phone"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="城市">
            <el-select v-model="editForm.address" placeholder="请选择城市">
              <el-option
                v-for="item in addressOptions"
                :key="item.length"
                :label="item.city"
                :value="item.city"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市" prop="address2">
            <el-input v-model="editForm.address2"></el-input>
          </el-form-item>
          <el-form-item label="钱包金额" prop="money">
            <el-input v-model="editForm.money"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUserInfo">确 定</el-button>
        </span>
      </el-dialog>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    //验证邮箱规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail =
        /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/

      if (regEmail.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的邮箱'))
    }
    var checkSex = (rule, value, cb) => {
      const regSex = /^['男'|'女']$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的性别'))
    }
    var checkRealname = (rule, value, cb) => {
      const regSex = /^[\u4e00-\u9fa5]{2,4}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的姓名'))
    }
    var checkPhone = (rule, value, cb) => {
      const regSex =
        /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的手机号'))
    }
    var checkMoney = (rule, value, cb) => {
      //验证价格的正则表达式
      const regMoney = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

      if (regMoney.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的价格'))
    }
    return {
      addressOptions:'',
      //获取列表的参数
      queryInfo: {
        query: '',

      },
      userlist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      // 添加用户的表单数据
      addForm: {
        username: '',
        password: '',
      },
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '长度在 6 到 16 个字符',
            trigger: 'blur',
          },
        ],
      },
      editForm: {},
      editFormRules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur',
          },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          {
            validator: checkSex,
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: checkEmail,
            trigger: 'blur',
          },
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            validator: checkRealname,
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            validator: checkPhone,
            trigger: 'blur',
          },
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          {
            min: 3,
            max: 255,
            message: '请输入正确长度的地址',
            trigger: 'blur',
          },
        ],
        address2: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          {
            min: 3,
            max: 255,
            message: '请输入正确长度的地址',
            trigger: 'blur',
          },
        ],
        money: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          {
            validator: checkMoney,
            trigger: 'blur',
          },
        ],
      },
      sexOptions: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
    }
  },
  created() {
    this.getUserList()
    this.getCityList()
  },
  methods: {
    //获取城市列表
    async getCityList() {
      const { data: res } = await this.$http.get('/address/citylist', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.addressOptions = res.message.data

    },
    async getUserList() {
      const { data: res } = await this.$http.get('/list/userinfo', {
        params: this.queryInfo,
      })
      if (res.status !== 0) return this.$message.error(res.message)
      //console.log(res.message.data);
      this.userlist = res.message.data
      this.total = res.message.data.length
    },

    //监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮添加新用户
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/api/reguser',
          this.addForm
        )
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.addDialogVisible = false
        //重新获取用户列表
        this.getUserList()
      })
    },
    //修改对话框展示信息
    async showEditDialog(userid) {
      const { data: res } = await this.$http.get('/my/userinfo', {
        params: { id: userid },
      })
      if (res.status !== 0) return this.$message.error(res.message)


      this.editForm = res.message.data

      this.editDialogVisible = true
    },
    //监听修改用户对话框的关闭
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改用户信息并且提交
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.put('/my/userinfo', {
          sex: this.editForm.sex,
          nickname: this.editForm.nickname,
          email: this.editForm.email,
          realname: this.editForm.realname,
          phone: this.editForm.phone,
          address: this.editForm.address,
          address2: this.editForm.address2,
          money: this.editForm.money,
          id: this.editForm.id,
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible = false
        //重新获取用户列表
        this.getUserList()
      })
    },
    //根据ID删除对应用户
    async removeUserById(userid) {
      //弹框提示用户是否删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/my/deleteuser', {
          data: { id: userid },
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getUserList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>