<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userindex' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
            <el-button slot="append" icon="el-icon-search" @click="getCollectionList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 商品列表区域 -->
      <el-table :data="this.collectionlist" border stripe>
        <el-table-column :label="this.total" type="index"></el-table-column>
        <el-table-column label="商品图片">
          <template v-slot="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="GoodsImg(scope.row.goodsid)"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="商品名" prop="goodsname"></el-table-column>
        <el-table-column label="商品价格" prop="price"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template v-slot="scope">
            <el-button type="primary" @click="checkInfo(scope.row)">详情</el-button>
            <!-- 删除按钮 -->
            <el-button
              class="button"
              type="danger"
              icon="el-icon-delete"
              size="big"
              @click="removeGoodsById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: ''
      },
      activeIndex: '3',
      collectionlist: [],
      total: 0,
      tprice: 0
    }
  },
  created() {
    this.getCollectionList()
    window.sessionStorage.setItem('activeIndex', this.activeIndex)
  },
  methods: {
    //通过ID获取图片
    GoodsImg(id) {
      let goodsimg = 'https://api.muaailgl.top/img/' + id + '.jpg?r=' +Math.random()

      return goodsimg
    },

    async getCollectionList() {
      const { data: res } = await this.$http.get('/collection/getc', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.collectionlist = res.message.data
      this.total = res.message.data.length
      this.tprice = res.message.tprice


    },
    async removeGoodsById(collectionid) {
      //弹框提示商品是否删除
      const confirmResult = await this.$confirm(
        '此操作将会使该商品移出收藏夹, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/collection/deletec', {
          data: { id: collectionid }
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getCollectionList()
      }
    },
    checkInfo(items) {
      // 把商品id保存到seesion中
      window.sessionStorage.setItem('id', items.goodsid)
      //  保存商品名
      window.sessionStorage.setItem('goodsname', items.goodsname)
      this.$router.push('/goodsinfo')
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
}
.el-tag {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>