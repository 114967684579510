<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
        <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入用户名查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="14">
            <el-button type="primary" @click="addDialogVisible = true">添加管理员</el-button>
          </el-col>
          <el-col :span="1">
            <el-button
              class="changepwd"
              type="warning"
              @click="changePwd = true"
            >修改密码</el-button>
          </el-col>
        </el-row>

        <!-- 用户列表区域 -->
        <el-table :data="userlist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="用户名" prop="username"></el-table-column>
          <el-table-column label="性别" prop="sex"></el-table-column>
          <el-table-column label="昵称" prop="nickname"></el-table-column>
          <el-table-column label="邮箱" prop="email"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="Status($event,scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <!-- 修改按钮 -->
              <el-button
                class="button"
                type="primary"
                icon="el-icon-edit"
                size="big"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
              <!-- 删除按钮 -->
              <el-button
                class="button"
                type="danger"
                icon="el-icon-delete"
                size="big"
                @click="removeUserById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-card>
      <!-- 添加用户的对话框 -->
      <el-dialog
        title="添加管理员"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="addForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改用户的对话框 -->
      <el-dialog
        title="修改用户"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="editForm.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="editForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="editForm.sex" placeholder="请选择性别">
              <el-option
                v-for="item in sexOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editForm.email"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUserInfo">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改密码对话框 -->
      <el-dialog
        title="修改密码"
        :visible.sync="changePwd"
        width="400px"
        @close="changePwdDialogClosed"
        append-to-body
      >
        <!-- 内容主题区域 -->
        <el-form :model="changePwdForm" :rules="changePwdFormRules" ref="changePwdFormRef">
          <el-form-item prop="passwordold">
            <el-input
              placeholder="请输入旧密码"
              v-model="changePwdForm.passwordold"
              prefix-icon="el-icon-lock"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="请输入新密码"
              v-model="changePwdForm.password"
              prefix-icon="el-icon-lock"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input
              placeholder="请再次输入新密码"
              v-model="changePwdForm.password2"
              prefix-icon="el-icon-lock"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="changePwd = false">取 消</el-button>
          <el-button type="primary" @click="editPassword">确 定</el-button>
        </span>
      </el-dialog>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    var checkPass2 = (rule, value, cb) => {
      if (value === '') {
        cb(new Error('请再次输入密码'))
      } else if (value !== this.changePwdForm.password) {
        cb(new Error('两次输入密码不一致!'))
      } else {
        cb()
      }
    }
    //验证邮箱规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/

      if (regEmail.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的邮箱'))
    }
    var checkSex = (rule, value, cb) => {
      const regSex = /^['男'|'女']$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的性别'))
    }
    return {
      changePwd:false,
      changePwdForm: {
        passwordold:'',
        password:'',
        password2:'',
      },
      //获取用户列表的参数
      queryInfo: {
        query: '',
      },
      userlist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      // 添加用户的表单数据
      addForm: {
        username: '',
        password: ''
      },
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '长度在 6 到 16 个字符',
            trigger: 'blur'
          }
        ]
      },
      editForm: {},
      editFormRules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          {
            validator: checkSex,
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: checkEmail,
            trigger: 'blur'
          }
        ]
      },
      sexOptions: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      changePwdFormRules: {
        passwordold: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password2: [
          {
            validator: checkPass2,
            trigger: 'blur'
          },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    //调用接口修改密码
    editPassword() {
      this.$refs.changePwdFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.put('/admmy/adminupdatepwd', {
          oldPwd: this.changePwdForm.passwordold,
          newPwd: this.changePwdForm.password,
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.changePwd = false
      })
    },
    changePwdDialogClosed() {
      this.$refs.changePwdFormRef.resetFields()
    },
    async getUserList() {
      console.log(this.queryInfo)
      const { data: res } = await this.$http.get('/list/admininfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)
      //console.log(res.message.data);
      this.userlist = res.message.data
      this.total = res.message.data.length
    },

    //监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮添加新用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/api/regadmin',
          this.addForm
        )
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.addDialogVisible = false
        //重新获取用户列表
        this.getUserList()
      })
    },
    async showEditDialog(userid) {
      const { data: res } = await this.$http.get('/admmy/admininfo', {
        params: { id: userid }
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.editForm = res.message.data
      this.editDialogVisible = true
    },
    //监听修改用户对话框的关闭
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改用户信息并且提交
    editUserInfo() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        console.log(this.editForm)
        const { data: res } = await this.$http.put('/admmy/admininfo', {
          sex: this.editForm.sex,
          nickname: this.editForm.nickname,
          email: this.editForm.email,
          id: this.editForm.id
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible = false
        //重新获取用户列表
        this.getUserList()
      })
    },
    async removeUserById(userid) {
      //弹框提示用户是否删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/admmy/deleteadmin', {
          data: { id: userid }
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getUserList()
      }
    },
    async Status(data, b) {
      const { data: res } = await this.$http.put('/admmy/admininfo', {
        id: b.id,
        status: data
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getUserList()
    }
  }
}
</script>

<style lang="less" scoped>
.button {
  margin-left: 16px;
}
</style>