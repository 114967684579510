<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userindex' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>我的订单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
            <el-button slot="append" icon="el-icon-search" @click="getOrderList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 商品列表区域 -->
      <el-table :data="this.orderlist" border stripe>
        <el-table-column :label="this.total" type="index"></el-table-column>
        <el-table-column label="会员名" prop="username"></el-table-column>
        <el-table-column label="商品名" prop="goodsname"></el-table-column>
        <el-table-column label="数量" prop="number"></el-table-column>
        <el-table-column label="商品价格" prop="price"></el-table-column>
        <el-table-column label="实付金额" prop="actualpay"></el-table-column>
        <el-table-column label="下单日期" prop="date"></el-table-column>
        <el-table-column label="真实姓名" prop="realname"></el-table-column>
        <el-table-column label="电话号码" prop="phone"></el-table-column>
        <el-table-column label="城市" prop="address"></el-table-column>
        <el-table-column label="地址" prop="address2"></el-table-column>
        <el-table-column label="支付状态" width="100px">
          <template v-slot="scope">
            <el-tag type="success" effect="dark">
              {{
              getpState(scope.row)
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100px">
          <template v-slot="scope">
            <el-tag type="success" effect="dark">
              {{
              getState(scope.row)
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230px">
          <template v-slot="scope">
            <el-button
              type="primary"
              @click="orderConfirm(scope.row.id)"
              :disabled="orderConfirmBtn(scope.row.dstate)"
            >确认收货</el-button>
            <el-button
              type="primary"
              @click="comment(scope.row.id)"
              :disabled="
                !orderConfirmBtn(scope.row.dstate) || scope.row.dstate === -3 && scope.row.dstate !== -2
              "
            >评论商品</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      title="评价商品"
      :visible.sync="commentDialogVisible"
      width="30%"
      @close="commentDialogClosed"
      append-to-body
    >
      <!-- 内容主题区域 -->
      <el-form :model="commentinfo" ref="commentInfoRef" label-width="80px">
        <el-form-item label="评价星级" prop="level">
          <el-rate v-model="commentinfo.level" show-text></el-rate>
        </el-form-item>
        <el-form-item label="评价内容" prop="textarea1">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入评价内容内容"
            v-model="commentinfo.textarea1"
            class="commentinput"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="commentDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commentConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: ''
      },
      commentDisabled: false,
      commentDialogVisible: false,
      activeIndex: '5',
      orderlist: [],
      orderinfo: [],
      commentinfo: {},
      total: 0
    }
  },
  created() {
    this.getOrderList()
    this.getState()
    window.sessionStorage.setItem('activeIndex', this.activeIndex)
  },
  methods: {
    async comment(commentid) {
      const { data: res } = await this.$http.get('/my/orderinfo', {
        params: { id: commentid }
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.orderinfo = res.message.data

      this.commentDialogVisible = true
    },
    commentDialogClosed() {
      this.$refs.commentInfoRef.resetFields()
    },
    async getOrderList() {
      const { data: res } = await this.$http.get('/my/orderinfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.orderlist = res.message.data
      this.total = res.message.data.length
    },
    checkInfo(items) {
      // 把商品id保存到seesion中
      window.sessionStorage.setItem('id', items.goodsid)
      //  保存商品名
      window.sessionStorage.setItem('goodsname', items.goodsname)
      this.$router.push('/goodsinfo')
    },
    getState(items) {
      if (items.dstate === 2) {
        return '待发货'
      }
      if (items.dstate === 1) {
        return '配送中'
      }
      if (items.dstate === 0) {
        return '已送达'
      }
      if (items.dstate === -1) {
        return '订单已完成'
      }
      if (items.dstate === -2) {
        return '订单已取消'
      }
      if (items.dstate === -3) {
        return '订单已评论'
      }
    },
    getpState(items) {
      if (items.pstate === 1) {
        return '未支付'
      }
      if (items.pstate === 0) {
        return '支付成功'
      }
    },
    async orderConfirm(orderid) {
      //弹框提示商品是否删除
      const confirmResult = await this.$confirm(
        '已收到商品了吗？此操作将会完成订单, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消收货'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res1 } = await this.$http.put('/adm/updateorder', {
          id: orderid,
          dstate: -1
        })
        if (res1.status !== 0) return this.$message.error(res1.message)
        this.$message.success('收货成功！订单已完成')
        this.getOrderList()
      }
    },
    //确认收货按钮的状态
    orderConfirmBtn(dstate) {
      if (dstate <= -1) {
        return true
      } else {
        return false
      }
    },
    async commentConfirm() {
      //上传评论
      const { data: res } = await this.$http.post('/my/uploadcomment', {
        goodsname: this.orderinfo.goodsname,
        content: this.commentinfo.textarea1,
        level: this.commentinfo.level
      })
      if (res.status !== 0) return this.$message.error(res.message)
      //修改订单状态
      const { data: res1 } = await this.$http.put('/adm/updateorder', {
        id: this.orderinfo.id,
        dstate: -3
      })
      if (res1.status !== 0) return this.$message.error(res1.message)
      this.$message.success('评论成功！')

      this.commentDialogVisible = false
      this.getOrderList()
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
}
.el-tag {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
.commentinput {
  margin-top: 15px;
}
</style>