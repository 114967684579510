<template>
  <div class="reguser_container">
    <div class="reguser_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="@/assets/logo.png" alt />
      </div>
      <!-- 注册表单区域 -->
      <el-form
        ref="reguserFormRef"
        :rules="reguserFormRules"
        label-width="0px"
        :model="reguserform"
        class="reguser_form"
      >
        <h3 class="titleU">会员注册</h3>
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input placeholder="请输入用户名" v-model="reguserform.username" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="reguserform.password"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            placeholder="请再次输入密码"
            v-model="reguserform.password2"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" round @click="reguserUser">确认注册</el-button>
          <el-button type="warning" round @click="loginUser">返回登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkPass2 = (rule, value, cb) => {
      if (value === '') {
        cb(new Error('请再次输入密码'))
      } else if (value !== this.reguserform.password) {
        cb(new Error('两次输入密码不一致!'))
      } else {
        cb()
      }
    }
    return {
      //这是注册表单的数据对象
      reguserform: {
        username: '',
        password: '',
        password2: ''
      },
      reguserFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password2: [
          {
            validator: checkPass2,
            trigger: 'blur'
          },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    //注册接口调用
    reguserUser() {
      this.$refs.reguserFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/api/reguser',
          this.reguserform
        )

        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)

        this.$refs.reguserFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.post(
            '/api/login',
            this.reguserform
          )

          if (res.status !== 0) return this.$message.error(res.message)
          window.sessionStorage.setItem('token', res.message.token)
          this.$router.push('/edituserinfo')
        })
      })
    },
    loginUser() {
      this.$router.push('/loginuser')
    }
  }
}
</script>

<style lang="less" scoped>
.reguser_container {
  background-color: #c2c2c2;
  height: 100%;
}
.reguser_box {
  width: 350px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);

  .avatar_box {
    height: 100px;
    width: 100px;
    border: 1px solid #340f88;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #727272;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -110%);
    background-color: #ffffff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #9e9e9e;
    }
  }
}

.reguser_form {
  position: absolute;
  top: 0%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  position: absolute;
  left: 18%;
  top: 100%;
}

.titleU {
  text-align: center;
}
</style>
