import { render, staticRenderFns } from "./GoodsIndex.vue?vue&type=template&id=57e9bf44&scoped=true&"
import script from "./GoodsIndex.vue?vue&type=script&lang=js&"
export * from "./GoodsIndex.vue?vue&type=script&lang=js&"
import style0 from "./GoodsIndex.vue?vue&type=style&index=0&id=57e9bf44&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e9bf44",
  null
  
)

export default component.exports