<template>
  <!--动态将图片轮播图的容器高度设置成与图片一致-->
  <el-carousel :interval="4000" id="el-carousel" type="card" indicator-position="none">
    <el-carousel-item
      v-for="item in promotionList"
      :key="item.id"
      @click.native="clickImg(item.goodsid)"
    >
      <el-image
        style="width: 100%; height: 100%"
        :src="GoodsImg(item.goodsid)"
        fit="fill"
      ></el-image>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'zmd',
  data() {
    return {
      promotionList: [],
      bannerHeight: 750,
      screenWidth: 1920,

      // 浏览器宽度
      screenWidth: 0
    }
  },
  mounted() {
    this.setSize1()
    const that = this
    //监听浏览器窗口大小改变
    window.addEventListener(
      'resize',
      function() {
        var width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        that.screenWidth = width
        that.setSize()
      },
      false
    )
  },
  created() {
    this.getPromotionGoodsList()
  },
  methods: {
    //通过ID获取图片
    GoodsImg(id) {
      let goodsimg =
        'https://api.muaailgl.top/img/' + id + '.jpg?r=' + Math.random()

      return goodsimg
    },
    async getPromotionGoodsList() {
      const { data: res } = await this.$http.get('/promotion/list')
      if (res.status !== 0) return this.$message.error(res.message)

      this.promotionList = res.message.data

      console.log(this.promotionList)
    },
    clickImg(id) {
      // 把商品id保存到seesion中
      window.sessionStorage.setItem('id', id)

      this.$router.push('/goodsinfo')
    },
    setSize1: function() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      this.screenWidth = width
      //图片                高 / 宽  700 / 1920
      this.bannerHeight = (750 / 1920) * this.screenWidth - 0
      document.getElementById('el-carousel').style.height =
        this.bannerHeight + 'px'
    },

    setSize: function() {
      this.bannerHeight = (750 / 1920) * this.screenWidth - 0
      document.getElementById('el-carousel').style.height =
        this.bannerHeight + 'px'
    }
  }
}
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #dfdfdf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #dfdfdf;
}
.el-carousel__container {
  height: 100% !important;
}
</style>