<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据报表</el-breadcrumb-item>
      <el-breadcrumb-item>销售数据</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
            <el-button slot="append" icon="el-icon-search" @click="getSalesList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- echart图表展示 -->
      <el-row :gutter="20" class="el-row">
        <el-col :span="24">
          <h3>商品销售数量（单位：份）</h3>
          <h3>总销售数量：{{this.total}}份</h3>
          <div id="main"></div>
        </el-col>
      </el-row>
      <!-- echart图表展示 -->
      <el-row :gutter="20" class="el-row">
        <el-col :span="24">
          <h3>商品销售金额（单位：￥）</h3>
          <h3>总销售金额：￥{{this.total2}}</h3>
          <div id="main2"></div>
        </el-col>
      </el-row>
      <!-- echart图表展示 -->
      <el-row :gutter="20" class="el-row">
        <el-col :span="24">
          <h3>商品销售净利润（单位：￥）</h3>
          <h3>销售总净利润：￥{{this.total3}}</h3>
          <div id="main3"></div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 2
      },
      salesList: [],
      salesList2: [],
      goodsList: [],
      total: 0,
      total2: 0,
      total3: 0
    }
  },
  created() {},
  // 此时页面上的元素，已经被渲染完毕了！
  mounted() {
    this.getGoodsList()
    this.getSalesList()
    // setTimeout(() => {
    //   this.saleNumber()
    //   this.salePrice()
    //   this.saleNetProfit()
    // }, 100)
  },
  methods: {
    //echart展示各个商品的净利润
    saleNetProfit() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main3'))

      this.total3 = 0
      //使用双循环匹配商品列表和销售列表中相同的商品名，将总利润扣除进价得到净利润
      for (var i = 0; i < this.salesList2.length; i++) {
        for (var j = 0; j < this.salesList2.length; j++) {
          if (this.goodsList[i].goodsname === this.salesList2[j].goodsname) {
            //算出单个商品总进价
            this.goodsList[i].pprice =
              this.goodsList[i].pprice * this.salesList2[j].salenumber
            //将总利润减去总进价获得净利润
            this.salesList2[j].totalsale =
              this.salesList2[j].totalsale - this.goodsList[i].pprice
            //解决精度问题
            this.salesList2[j].totalsale = parseFloat(
              parseFloat(this.salesList2[j].totalsale).toFixed(2)
            )
            //将每次算出的净利润相加得到总共的净利润
            this.total3 = this.total3 + Number(this.salesList2[j].totalsale)
            this.total3 = parseFloat(parseFloat(this.total3).toFixed(2))
          }
        }
      }

      let dataName = []
      let data1 = []
      let defaultData = []
      let maxData = 0
      this.salesList2.forEach(item => {
        dataName.push(item.goodsname)
        data1.push(item.totalsale)
        if (maxData < item.totalsale) {
          maxData = item.totalsale
        }
      })
      for (let i = 0; i < data1.length; i++) {
        defaultData.push(maxData)
      }
      var colorList = [
        '#00AAFF',
        '#A52A2A',
        '#FF752D',
        '#FFCF31',
        '#FF3129',
        '#006400',
        '#8B4513',
        '#FF8C00',
        '#FFB6C1',
        '#7B68EE',
        '#87CEFA',
        '#008000',
        '#D2B48C'
      ]

      var option = {
        backgroundColor: '#36467E',
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          position: function(point, params, dom, rect, size) {
            // console.log(point)

            return [point[0], point[1]]
          },
          confine: false,
          // formatter: '{b}:{c}'
          formatter: function(params) {
            // console.log(params)
            let title = params[0].name
            if (title.length > 30) {
              title =
                title.substring(0, 15) +
                '<br/>' +
                title.substring(15, 30) +
                '<br/>' +
                title.substring(30)
            } else if (title.length > 15) {
              title = title.substring(0, 15) + '<br/>' + title.substring(15)
            }
            let name = title + ' : ' + params[0].value
            return name
          }
        },
        // backgroundColor: 'rgb(20,28,52)',
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            // axisLabel: {
            //   show: true,
            //   textStyle: {
            //     color: '#fff'
            //   },
            //   rotate: 0
            // },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                value = value.length > 7 ? value.substring(0, 7) + '...' : value
                return value
              }
            },
            data: dataName
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                return value
              }
            },
            data: data1
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: [0, 30, 30, 0],
                color: params => {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(54,69,129,1)'
                    },
                    {
                      offset: 0.8,
                      color: colorList[params.dataIndex]
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0.8)'
                    }
                  ])
                }
                // color: (params) => {
                //   return colorList[params.dataIndex]
                // }
              }
            },
            barWidth: 15,
            data: data1
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            data: defaultData,
            itemStyle: {
              normal: {
                color: '#1B375E',
                barBorderRadius: [0, 30, 30, 0]
              }
            }
          }
        ]
      }

      // 展示数据
      myChart.setOption(option)
    },
    //echart展示各个商品的销售额
    salePrice() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main2'))

      this.total2 = 0

      for (var i = 0; i < this.salesList.length; i++) {
        this.total2 = this.total2 + Number(this.salesList2[i].totalsale)
        this.total2 = parseFloat(parseFloat(this.total2).toFixed(2))
      }

      let dataName = []
      let data1 = []
      let defaultData = []
      let maxData = 0
      this.salesList.forEach(item => {
        dataName.push(item.goodsname)
        data1.push(item.totalsale)
        if (maxData < item.totalsale) {
          maxData = item.totalsale
        }
      })
      for (let i = 0; i < data1.length; i++) {
        defaultData.push(maxData)
      }
      var colorList = [
        '#00AAFF',
        '#A52A2A',
        '#FF752D',
        '#FFCF31',
        '#FF3129',
        '#006400',
        '#8B4513',
        '#FF8C00',
        '#FFB6C1',
        '#7B68EE',
        '#87CEFA',
        '#008000',
        '#D2B48C'
      ]

      var option = {
        backgroundColor: '#36467E',
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          position: function(point, params, dom, rect, size) {
            // console.log(point)

            return [point[0], point[1]]
          },
          confine: false,
          // formatter: '{b}:{c}'
          formatter: function(params) {
            // console.log(params)
            let title = params[0].name
            if (title.length > 30) {
              title =
                title.substring(0, 15) +
                '<br/>' +
                title.substring(15, 30) +
                '<br/>' +
                title.substring(30)
            } else if (title.length > 15) {
              title = title.substring(0, 15) + '<br/>' + title.substring(15)
            }
            let name = title + ' : ' + params[0].value
            return name
          }
        },
        // backgroundColor: 'rgb(20,28,52)',
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            // axisLabel: {
            //   show: true,
            //   textStyle: {
            //     color: '#fff'
            //   },
            //   rotate: 0
            // },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                value = value.length > 7 ? value.substring(0, 7) + '...' : value
                return value
              }
            },
            data: dataName
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                return value
              }
            },
            data: data1
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: [0, 30, 30, 0],
                color: params => {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(54,69,129,1)'
                    },
                    {
                      offset: 0.8,
                      color: colorList[params.dataIndex]
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0.8)'
                    }
                  ])
                }
                // color: (params) => {
                //   return colorList[params.dataIndex]
                // }
              }
            },
            barWidth: 15,
            data: data1
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            data: defaultData,
            itemStyle: {
              normal: {
                color: '#1B375E',
                barBorderRadius: [0, 30, 30, 0]
              }
            }
          }
        ]
      }

      // 展示数据
      myChart.setOption(option)
    },
    //echart展示各个商品的销售数量
    saleNumber() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main'))
      this.total = 0
      for (var i = 0; i < this.salesList.length; i++) {
        this.total = this.total + this.salesList[i].salenumber
        this.total = parseInt(this.total)
      }

      let dataName = []
      let data1 = []
      let defaultData = []
      let maxData = 0
      this.salesList.forEach(item => {
        dataName.push(item.goodsname)
        data1.push(item.salenumber)
        if (maxData < item.salenumber) {
          maxData = item.salenumber
        }
      })
      for (let i = 0; i < data1.length; i++) {
        defaultData.push(maxData)
      }
      var colorList = [
        '#00AAFF',
        '#A52A2A',
        '#FF752D',
        '#FFCF31',
        '#FF3129',
        '#006400',
        '#8B4513',
        '#FF8C00',
        '#FFB6C1',
        '#7B68EE',
        '#87CEFA',
        '#008000',
        '#D2B48C'
      ]

      var option = {
        backgroundColor: '#36467E',
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          position: function(point, params, dom, rect, size) {
            // console.log(point)

            return [point[0], point[1]]
          },
          confine: false,
          // formatter: '{b}:{c}'
          formatter: function(params) {
            // console.log(params)
            let title = params[0].name
            if (title.length > 30) {
              title =
                title.substring(0, 15) +
                '<br/>' +
                title.substring(15, 30) +
                '<br/>' +
                title.substring(30)
            } else if (title.length > 15) {
              title = title.substring(0, 15) + '<br/>' + title.substring(15)
            }
            let name = title + ' : ' + params[0].value
            return name
          }
        },
        // backgroundColor: 'rgb(20,28,52)',
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            // axisLabel: {
            //   show: true,
            //   textStyle: {
            //     color: '#fff'
            //   },
            //   rotate: 0
            // },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                value = value.length > 7 ? value.substring(0, 7) + '...' : value
                return value
              }
            },
            data: dataName
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function(value) {
                return value
              }
            },
            data: data1
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: [0, 30, 30, 0],
                color: params => {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(54,69,129,1)'
                    },
                    {
                      offset: 0.8,
                      color: colorList[params.dataIndex]
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0.8)'
                    }
                  ])
                }
                // color: (params) => {
                //   return colorList[params.dataIndex]
                // }
              }
            },
            barWidth: 15,
            data: data1
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            data: defaultData,
            itemStyle: {
              normal: {
                color: '#1B375E',
                barBorderRadius: [0, 30, 30, 0]
              }
            }
          }
        ]
      }

      // 展示数据
      myChart.setOption(option)
    },
    //获取销售数据
    async getSalesList() {
      const { data: res } = await this.$http.get('/sales/getsales', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.salesList = res.message.data
      this.salesList2 = res.message.data

      this.saleNumber()
      this.salePrice()
      this.getGoodsList()
      setTimeout(() => {
        this.saleNetProfit()
      }, 50)
    },
    //获取商品数据
    async getGoodsList() {
      const { data: res } = await this.$http.get('/list/goodsinfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.goodsList = res.message.data
    }
  }
}
</script>

<style lang="less" scoped>
#main {
  margin-top: 15px;
  width: 100%;
  height: 500px;
  background-color: 'pink';
}
#main2 {
  margin-top: 15px;
  width: 100%;
  height: 500px;
  background-color: 'pink';
}
#main3 {
  margin-top: 15px;
  width: 100%;
  height: 500px;
  background-color: 'pink';
}
</style>