<template>
  <div class="editUser_container">
    <div class="editUser_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="@/assets/logo.png" alt />
      </div>
      <!-- 注册表单区域 -->
      <el-form
        ref="editUserFormRef"
        :rules="editUserFormRules"
        label-width="0px"
        :model="editUserform"
        class="editUser_form"
      >
        <h3 class="titleU">会员信息完善</h3>

        <el-form-item prop="nickname">
          <el-input placeholder="请输入昵称" v-model="editUserform.nickname" prefix-icon="el-icon-user"></el-input>
        </el-form-item>

        <el-form-item prop="sex">
          <el-select v-model="editUserform.sex" placeholder="请选择性别">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="email">
          <el-input placeholder="请输入邮箱" v-model="editUserform.email" prefix-icon="el-icon-user"></el-input>
        </el-form-item>

        <el-form-item prop="realname">
          <el-input
            placeholder="请输入真实姓名"
            v-model="editUserform.realname"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>

        <el-form-item prop="phone">
          <el-input placeholder="请输入手机号" v-model="editUserform.phone" prefix-icon="el-icon-phone"></el-input>
        </el-form-item>

        <el-form-item prop="address">
          <el-select v-model="editUserform.address" placeholder="请选择城市">
            <el-option
              v-for="item in addressOptions"
              :key="item.length"
              :label="item.city"
              :value="item.city"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="address1">
          <el-input placeholder="请输入地址" v-model="editUserform.address2" prefix-icon="el-icon-user"></el-input>
        </el-form-item>

        <el-form-item class="btns">
          <el-button type="primary" round @click="editUser">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    //验证邮箱规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/

      if (regEmail.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的邮箱'))
    }
    var checkSex = (rule, value, cb) => {
      const regSex = /^['男'|'女']$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的性别'))
    }
    var checkRealname = (rule, value, cb) => {
      const regSex = /^[\u4e00-\u9fa5]{2,4}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的姓名'))
    }
    var checkPhone = (rule, value, cb) => {
      const regSex = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的手机号'))
    }
    return {
      //这是注册表单的数据对象
      editUserform: {},
      editUserFormRules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          {
            validator: checkSex,
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: checkEmail,
            trigger: 'blur'
          }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            validator: checkRealname,
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ],
        address: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        address2: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          {
            min: 3,
            max: 255,
            message: '请输入正确长度的地址',
            trigger: 'blur'
          }
        ]
      },
      sexOptions: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      addressOptions:[],
      total:'',
      queryInfo: {
        query: '',
      },
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {
      const { data: res } = await this.$http.get('/address/citylist', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.addressOptions = res.message.data
      this.total = res.message.data.length

    },
    //修改用户信息接口调用
    editUser() {
      this.$refs.editUserFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/my/userinfo', {
          nickname: this.editUserform.nickname,
          sex: this.editUserform.sex,
          email: this.editUserform.email,
          realname: this.editUserform.realname,
          phone: this.editUserform.phone,
          address: this.editUserform.address,
          address2: this.editUserform.address2,
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.$router.push('/loginuser')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.editUser_container {
  background-color: #c2c2c2;
  height: 100%;
}
.editUser_box {
  width: 350px;
  height: 560px;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);

  .avatar_box {
    height: 100px;
    width: 100px;
    border: 1px solid #340f88;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #727272;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -110%);
    background-color: #ffffff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #9e9e9e;
    }
  }
}

.editUser_form {
  position: absolute;
  top: 0%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%);
}

.titleU {
  text-align: center;
}
</style>
