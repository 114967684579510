<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true">添加商品</el-button>
          </el-col>
        </el-row>

        <!-- 商品列表区域 -->
        <el-table :data="this.goodslist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="商品图片">
            <template v-slot="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="GoodsImg(scope.row.id)"
                fit="contain"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="商品名" prop="goodsname"></el-table-column>
          <el-table-column label="商品标签" prop="goodslabel"></el-table-column>
          <el-table-column label="商品价格" prop="price"></el-table-column>
          <el-table-column label="商品数量" prop="number"></el-table-column>
          <el-table-column label="商品进价" prop="pprice"></el-table-column>
          <el-table-column label="是否是促销商品" prop="state">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.state"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="State($event,scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="是否上架" prop="status">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="Status($event,scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <!-- 修改按钮 -->
              <el-button
                class="button"
                type="primary"
                icon="el-icon-edit"
                size="big"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
              <!-- 删除按钮 -->
              <el-button
                class="button"
                type="danger"
                icon="el-icon-delete"
                size="big"
                @click="removeGoodsById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- 添加商品 -->
      <el-dialog title="添加商品" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <!-- 内容主题区域 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
          <el-form-item label="商品名" prop="goodsname">
            <el-input v-model="addForm.goodsname"></el-input>
          </el-form-item>
          <el-form-item label="商品标签" prop="goodslabel">
            <el-input v-model="addForm.goodslabel"></el-input>
          </el-form-item>
          <el-form-item label="商品价格" prop="price">
            <el-input v-model="addForm.price"></el-input>
          </el-form-item>
          <el-form-item label="商品数量" prop="number">
            <el-input v-model="addForm.number"></el-input>
          </el-form-item>
          <el-form-item label="商品进价" prop="pprice">
            <el-input v-model="addForm.pprice"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addGoods">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改商品的对话框 -->
      <el-dialog
        title="修改商品"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
          <el-form-item label="商品图片">
            <el-upload
              :data="editForm"
              class="avatar-uploader"
              action="https://api.muaailgl.top/goods/uploadimg"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品名" prop="goodsname">
            <el-input v-model="editForm.goodsname"></el-input>
          </el-form-item>
          <el-form-item label="商品标签" prop="goodslabel">
            <el-input v-model="editForm.goodslabel"></el-input>
          </el-form-item>
          <el-form-item label="价格" prop="price">
            <el-input v-model="editForm.price"></el-input>
          </el-form-item>
          <el-form-item label="商品数量" prop="number">
            <el-input v-model="editForm.number"></el-input>
          </el-form-item>
          <el-form-item label="进价" prop="pprice">
            <el-input v-model="editForm.pprice"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editGoodsInfo">确 定</el-button>
        </span>
      </el-dialog>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    //验证价格规则
    var checkPrice = (rule, value, cb) => {
      //验证价格的正则表达式
      const regPrice = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

      if (regPrice.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的价格'))
    }
    var checkNumber = (rule, value, cb) => {
      //验证价格的正则表达式
      const regNumber = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

      if (regNumber.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的数量'))
    }
    return {
      imageUrl: '',
      queryInfo: {
        query: ''
      },
      goodslist: [],
      total: 0,
      //控制添加商品对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      // 添加商品的表单数据
      addForm: {},
      addFormRules: {
        goodsname: [
          { required: true, message: '请输入商品名', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
        goodslabel: [
          { required: true, message: '请输入商品标签', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
        price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' },
          {
            validator: checkPrice,
            trigger: 'blur'
          }
        ],
        number: [
          { required: true, message: '请输入商品数量', trigger: 'blur' },
          {
            validator: checkNumber,
            trigger: 'blur'
          }
        ],
        pprice: [
          { required: true, message: '请输入商品进价', trigger: 'blur' },
          {
            validator: checkPrice,
            trigger: 'blur'
          }
        ]
      },
      editForm: {},
      editFormRules: {
        goodsname: [
          { required: true, message: '请输入商品名', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
        goodslabel: [
          { required: true, message: '请输入商品标签', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
        price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' },
          {
            validator: checkPrice,
            trigger: 'blur'
          }
        ],
        number: [
          { required: true, message: '请输入商品数量', trigger: 'blur' },
          {
            validator: checkNumber,
            trigger: 'blur'
          }
        ],
        pprice: [
          { required: true, message: '请输入商品进价', trigger: 'blur' },
          {
            validator: checkPrice,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getGoodsList()
  },

  methods: {
    async State(data, b) {
      const { data: res } = await this.$http.put('/goods/update', {
        id: b.id,
        state: data
      })
      if (data === 0) {
        this.$http.post('/promotion/upload', {
          goodsid: b.id
        })
      }
      if (data === 1) {
        this.$http.delete('/promotion/delete', {
          data: { goodsid: b.id }
        })
      }
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getUserList()
    },
    async Status(data, b) {
      const { data: res } = await this.$http.put('/goods/update', {
        id: b.id,
        status: data
      })
      if (data === 1) {
        this.$http.delete('/cart/deletecart', {
          data: { goodsid: b.id }
        })
        this.$http.delete('/collection/deletec', {
          data: { goodsid: b.id }
        })
      }
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getUserList()
    },
    //通过ID获取图片
    GoodsImg(id) {
      //https://api.muaailgl.top/img/13.jpg
      let goodsimg = 'https://api.muaailgl.top/img/' + id + '.jpg?r=' +Math.random()

      return goodsimg
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async getGoodsList() {
      const { data: res } = await this.$http.get('/list/goodsinfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.goodslist = res.message.data
      this.total = res.message.data.length
    },
    //监听添加商品对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮添加新商品
    addGoods() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/goods/upload',
          this.addForm
        )

        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.addDialogVisible = false
        //重新获取商品列表
        this.getGoodsList()
      })
    },
    //点击修改按钮后获取该商品信息并且填入表单
    async showEditDialog(goodsid) {
      const { data: res } = await this.$http.get('/goods/goodsinfo', {
        params: { id: goodsid }
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.editForm = res.message.data

      this.editDialogVisible = true
    },
    //监听修改商品对话框的关闭
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改商品信息并且提交
    editGoodsInfo() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/goods/update', {
          goodsname: this.editForm.goodsname,
          goodslabel: this.editForm.goodslabel,
          price: this.editForm.price,
          number: this.editForm.number,
          pprice: this.editForm.pprice,
          id: this.editForm.id
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible = false
        //重新获取商品列表
        this.getGoodsList()
        //刷新整个页面
        this.$router.go(0)
      })
    },
    //根据ID删除对应商品
    async removeGoodsById(goodsid) {
      //弹框提示商品是否删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      if (confirmResult === 'confirm') {
        
        const { data: res } = await this.$http.delete('/goods/delete', {
          data: { id: goodsid }
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getGoodsList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>