<template>
  <div>
    <el-card style="width: 66%; margin-left: 50%; transform: translate(-50%);">
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userindex' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商品详情页</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row :gutter="20">
        <el-col :span="16">
          <div>
            <img
              :src="GoodsImg(this.goodsInfo.id)"
              class="infoimage"
            />
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <div class="goodsInfo">
              <span class="namefont">
                {{ this.goodsInfo.goodsname }}
                <br />
                <br />
              </span>
              <span class="otherfont">
                标签：{{ this.goodsInfo.goodslabel }}
                <br />
                <br />
              </span>
              <span class="otherfont">
                库存：{{ this.goodsInfo.number }}
                <br />
                <br />
              </span>
              <span class="otherfont">
                商品价格：￥ {{ this.goodsInfo.price }}
                <br />
                <br />
              </span>
              综合评价等级：
              <el-rate
                v-model="value"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}"
              ></el-rate>
            </div>
            <div class="btn">
              <br />
              <br />
              <el-input-number v-model="num" @change="handleChange" :min="1" label="描述文字"></el-input-number>
              <br />
              <br />
              <el-button type="primary" round @click="order">购买</el-button>
              <el-button type="danger" round @click="addCart">放入购物车</el-button>
              <el-button
                class="collection"
                type="warning"
                icon="el-icon-star-off"
                circle
                @click="addCollection"
              ></el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- 评论区 -->
      <h3>评论区</h3>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-for="(items,index) in commentlist"
          :key="index"
          :title="'会员名：' + items.username + '\u3000\u3000'+ '评价等级：' +items.level +'星'+ '\u3000\u3000' + '评价日期：' + items.date"
          :name="index"
        >
          <div>{{items.content}}</div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
export default {

  data() {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 2
      },
      value: '',
      activeIndex: '1',
      num: 1,
      goodsInfo: {
        id: '',
        goodsname: '',
        goodslabel: '',
        price: '',
        number: ''
      },
      commentlist: []
    }
  },
  created() {
    this.getGoodsId()
  },
  methods: {
    //通过ID获取图片
    GoodsImg(id) {
      let goodsimg = 'https://api.muaailgl.top/img/' + id + '.jpg?r=' +Math.random()

      return goodsimg
    },
    handleChange(value) {
      console.log(value)
    },
    //从session里取出之前存放的商品ID
    getGoodsId() {
      const goodsid = window.sessionStorage.getItem('id')
      this.goodsInfo.id = goodsid
      this.getGoodsInfo()
    },
    async getGoodsInfo() {
      const { data: res } = await this.$http.get('/goods/goodsinfo', {
        params: { id: this.goodsInfo.id }
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.goodsInfo = res.message.data

      this.getCommentLits()
    },
    //获取评论
    async getCommentLits() {

      const { data: res } = await this.$http.get('/my/getcommentlist', {
        params: { goodsname: this.goodsInfo.goodsname }
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.commentlist = res.message.data
      for (let i = 0; i < this.commentlist.length; i++) {
        this.value = this.value + this.commentlist[i].level
      }

      this.value = Math.floor(this.value)
      this.value = this.value / this.commentlist.length
      this.value = parseFloat(parseFloat(this.value).toFixed(1))
    },
    order() {
      this.addCart()
      setTimeout(() => {
        this.$router.push('/myshoppingcart')
      }, 300)
    },
    async addCart() {
      const { data: res } = await this.$http.post('/cart/addcart', {
        goodsid: this.goodsInfo.id,
        goodsname: this.goodsInfo.goodsname,
        price: this.goodsInfo.price,
        number: this.num
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getGoodsInfo()
    },
    async addCollection() {
      const { data: res } = await this.$http.post('/collection/addanddeletec', {
        goodsid: this.goodsInfo.id,
        goodsname: this.goodsInfo.goodsname,
        price: this.goodsInfo.price
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getGoodsInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  float: left;
}
.namefont {
  font-size: 40px;
}
.otherfont {
  font-size: 20px;
}
.infoimage{

  width: 70%;
}
</style>