<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>会员规则</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入会员等级查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getRulelist"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true">添加会员规则</el-button>
          </el-col>
        </el-row>

        <!-- 列表区域 -->
        <el-table :data="this.rulelist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="会员等级" prop="level"></el-table-column>
          <el-table-column label="折扣" prop="discount"></el-table-column>
          <el-table-column label="消费金额" prop="totalcost"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <!-- 修改按钮 -->
              <el-button
                class="button"
                type="primary"
                icon="el-icon-edit"
                size="big"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
              <!-- 删除按钮 -->
              <el-button
                class="button"
                type="danger"
                icon="el-icon-delete"
                size="big"
                @click="removeRuleById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-card>
      <!-- 添加会员规则 -->
      <el-dialog
        title="添加会员规则"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
          <el-form-item label="会员等级" prop="level">
            <el-input v-model="addForm.level"></el-input>
          </el-form-item>
          <el-form-item label="折扣" prop="discount">
            <el-select v-model="addForm.discount" placeholder="请选择折扣力度">
              <el-option
                v-for="item in discountOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消费金额" prop="totalcost">
            <el-input v-model="addForm.totalcost"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRule">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改用户的对话框 -->
      <el-dialog
        title="修改用户"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
          <el-form-item label="会员等级" prop="level">
            <el-input v-model="editForm.level"></el-input>
          </el-form-item>
          <el-form-item label="折扣" prop="discount">
            <el-select v-model="editForm.discount" placeholder="请选择折扣力度">
              <el-option
                v-for="item in discountOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消费金额" prop="totalcost">
            <el-input v-model="editForm.totalcost"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRuleInfo">确 定</el-button>
        </span>
      </el-dialog>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    var checkMoney = (rule, value, cb) => {
      //验证价格的正则表达式
      const regMoney = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

      if (regMoney.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的价格'))
    }
    return {
      //获取用户列表的参数
      queryInfo: {
        query: '',
      },
      rulelist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      // 添加用户的表单数据
      addForm: {},
      addFormRules: {
        level: [
          { required: true, message: '请输入会员等级', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        discount: [
          { required: true, message: '请选择折扣力度', trigger: 'blur' }
        ],
        totalcost: [
          { required: true, message: '请输入消费金额', trigger: 'blur' },
          {
            validator: checkMoney,
            trigger: 'blur'
          }
        ]
      },
      editForm: {},
      editFormRules: {
        level: [
          { required: true, message: '请输入会员等级', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        discount: [
          { required: true, message: '请选择折扣力度', trigger: 'blur' }
        ],
        totalcost: [
          { required: true, message: '请输入消费金额', trigger: 'blur' },
          {
            validator: checkMoney,
            trigger: 'blur'
          }
        ]
      },
      discountOptions: [
        {
          value: 1,
          label: '无折扣'
        },
        {
          value: 0.95,
          label: '九五折'
        },
        {
          value: 0.9,
          label: '九折'
        },
        {
          value: 0.85,
          label: '八五折'
        },
        {
          value: 0.8,
          label: '八折'
        },
        {
          value: 0.75,
          label: '七五折'
        },
        {
          value: 0.7,
          label: '七折'
        },
        {
          value: 0.65,
          label: '六五折'
        },
        {
          value: 0.6,
          label: '六折'
        },
        {
          value: 0.55,
          label: '五五折'
        },
        {
          value: 0.5,
          label: '五折'
        },
        {
          value: 0.45,
          label: '四五折'
        },
        {
          value: 0.4,
          label: '四折'
        },
        {
          value: 0.35,
          label: '三五折'
        },
        {
          value: 0.3,
          label: '三折'
        },
        {
          value: 0.25,
          label: '二五折'
        },
        {
          value: 0.2,
          label: '两折'
        },
        {
          value: 0.15,
          label: '一五折'
        },
        {
          value: 0.1,
          label: '一折'
        }
      ]
    }
  },
  created() {
    this.getRulelist()
  },
  methods: {
    async getRulelist() {
      const { data: res } = await this.$http.get('/usersrules/rulelist', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)
      //console.log(res.message.data);
      this.rulelist = res.message.data
      this.total = res.message.data.length
    },

    //监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮添加新用户
    addRule() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/usersrules/upload',
          this.addForm
        )
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.addDialogVisible = false
        //重新获取用户列表
        this.getRulelist()
      })
    },
    //修改对话框展示信息
    async showEditDialog(ruleid) {
      const { data: res } = await this.$http.get('/usersrules/ruleinfo', {
        params: { id: ruleid }
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.editForm = res.message.data

      this.editDialogVisible = true
    },
    //监听修改对话框的关闭
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改信息并且提交
    editRuleInfo() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('/usersrules/update',this.editForm )
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible = false

        this.getRulelist()
      })
    },
    //根据ID删除
    async removeRuleById(ruleid) {
      //弹框提示是否删除
      const confirmResult = await this.$confirm(
        '此操作将删除该规则, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/usersrules/delete', {
          data: { id: ruleid }
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getRulelist()
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>