<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="@/assets/logo.png" alt />
      </div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :rules="loginFormRules"
        label-width="0px"
        :model="loginform"
        class="login_form"
      >
      <h3 class="titleU">会员登录</h3>
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input placeholder="请输入用户名" v-model="loginform.username" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="loginform.password"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" round @click="loginUser">登录</el-button>
          <el-button type="warning" round @click="reguserUser">注册</el-button>
          <el-button type="warning" round @click="adminUser">管理员登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //这是登录表单的数据对象
      loginform: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '长度在 6 到 16 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    //登录接口调用
    loginUser() {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/api/login',
          this.loginform
        )
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message.message)
        window.sessionStorage.setItem('token', res.message.token)
        this.$router.push('/userindex')
      })
    },
    reguserUser() {
      this.$router.push('/reguseruser')
    },
    adminUser() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background-color: #c2c2c2;
  height: 100%;
}
.login_box {
  width: 350px;
  height: 250px;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);

  .avatar_box {
    height: 100px;
    width: 100px;
    border: 1px solid #340f88;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #727272;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -110%);
    background-color: #ffffff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #9e9e9e;
    }
  }
}

.login_form {
  position: absolute;
  top: 0%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  position: absolute;
  left: 8%;
  top: 100%;
}

.titleU{
    text-align: center;
}
</style>
