<template>
  <div class="mainbox">
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userindex' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>我的信息</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 描述列表区 -->
      <el-descriptions
        class="margin-top"
        title="主要信息"
        :column="3"
        border
      >
        <template slot="extra"> 
          <el-button round type="primary" size="small" @click="showEditDialog">修改信息</el-button>
          <el-button
            class="changepwd"
            type="warning"
            size="small"
            round
            @click="editDialogVisible = true"
            >修改密码</el-button
          >
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ userinfo.username }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            昵称
          </template>
          {{ userinfo.nickname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user-solid"></i>
            性别
          </template>
          {{ userinfo.sex }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            邮箱
          </template>
          {{ userinfo.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            真实姓名
          </template>
          {{ userinfo.realname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ userinfo.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            地址
          </template>
          {{ userinfo.address + userinfo.address2 }}
        </el-descriptions-item>
      </el-descriptions>

      <div slot="header" class="clearfix">
        <span>我的信息</span>
      </div>
    </el-card>
    <!-- 修改密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="editDialogVisible"
      width="400px"
      @close="editDialogClosed"
      append-to-body
    >
      <!-- 内容主题区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef">
        <el-form-item prop="passwordold">
          <el-input
            placeholder="请输入旧密码"
            v-model="editForm.passwordold"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入新密码"
            v-model="editForm.password"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            placeholder="请再次输入新密码"
            v-model="editForm.password2"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPassword">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改用户信息"
      :visible.sync="editDialogVisible2"
      width="30%"
      @close="editDialogClosed2"
      append-to-body
    >
      <!-- 内容主题区域 -->
      <el-form
        :model="userinfo2"
        :rules="userInfoRules"
        ref="userInfoRef"
        label-width="80px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="userinfo2.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="userinfo2.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="userinfo2.sex" placeholder="请选择性别">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="userinfo2.email"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realname">
          <el-input v-model="userinfo2.realname"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="userinfo2.phone"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="城市">
          <el-select v-model="userinfo2.address" placeholder="请选择城市">
            <el-option
              v-for="item in addressOptions"
              :key="item.length"
              :label="item.city"
              :value="item.city"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="address2">
          <el-input v-model="userinfo2.address2"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="Confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    //验证邮箱规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail =
        /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/

      if (regEmail.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的邮箱'))
    }
    var checkSex = (rule, value, cb) => {
      const regSex = /^['男'|'女']$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的性别'))
    }
    var checkRealname = (rule, value, cb) => {
      const regSex = /^[\u4e00-\u9fa5]{2,4}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入正确的姓名'))
    }
    var checkPhone = (rule, value, cb) => {
      const regSex =
        /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/

      if (regSex.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的手机号'))
    }
    var checkPass2 = (rule, value, cb) => {
      if (value === '') {
        cb(new Error('请再次输入密码'))
      } else if (value !== this.editForm.password) {
        cb(new Error('两次输入密码不一致!'))
      } else {
        cb()
      }
    }
    return {
      queryInfo: {
        query: '',
      },
      activeIndex: '2',
      editDialogVisible: false,
      editDialogVisible2: false,
      userinfo: {},
      userinfo2: {},
      editForm: {
        passwordold: '',
        password: '',
        password2: '',
      },
      userInfoRules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur',
          },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
          {
            validator: checkSex,
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: checkEmail,
            trigger: 'blur',
          },
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            validator: checkRealname,
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            validator: checkPhone,
            trigger: 'blur',
          },
        ],
        address: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        address2: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          {
            min: 3,
            max: 255,
            message: '请输入正确长度的地址',
            trigger: 'blur',
          },
        ],
      },
      sexOptions: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
      addressOptions: [],
      editFormRules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur',
          },
        ],
        password2: [
          {
            validator: checkPass2,
            trigger: 'blur',
          },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.getUserInfo()
    this.getCityList()
    window.sessionStorage.setItem('activeIndex', this.activeIndex)
  },
  methods: {
    //修改对话框展示信息
    async showEditDialog() {
      const { data: res } = await this.$http.get('/my/userinfo')
      if (res.status !== 0) return this.$message.error(res.message)

      this.userinfo2 = res.message.data

      this.editDialogVisible2 = true
    },
    //监听修改用户对话框的关闭
    editDialogClosed2() {
      this.$refs.userinfoRef.resetFields()
      
    },
    //获取城市列表
    async getCityList() {
      const { data: res } = await this.$http.get('/address/citylist', {
        params: this.queryInfo,
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.addressOptions = res.message.data
    },
    //查询用户信息
    async getUserInfo() {
      const { data: res } = await this.$http.get('/my/userinfo')
      if (res.status !== 0) return this.$message.error(res.message)

      this.userinfo = res.message.data
    },
    //修改用户信息
    Confirm() {
      this.$refs.userInfoRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.put('/my/userinfo', {
          sex: this.userinfo2.sex,
          nickname: this.userinfo2.nickname,
          email: this.userinfo2.email,
          realname: this.userinfo2.realname,
          phone: this.userinfo2.phone,
          address: this.userinfo2.address,
          address2: this.userinfo2.address2,
          id: this.userinfo2.id,
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible2 = false
        //重新获取用户信息
        this.getUserInfo()
      })
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    editPassword() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.put('/my/updatepwd', {
          oldPwd: this.editForm.passwordold,
          newPwd: this.editForm.password,
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.editDialogVisible = false
      })
    },
  },
}
</script>

<style lang="less" scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 900px;
}

.mainbox {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.changepwd {
  float: right;
}
</style>