<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="@/assets/logo.png" alt class="logo" />
        <span>果果生鲜销售后台管理</span>
      </div>
      <div>
        <el-tag type="success" effect="dark">欢迎您：{{this.userInfo.username}}</el-tag>
        <el-button size="small" type="info" @click="logout">退出</el-button>
      </div>
    </el-header>
    <!-- 页面主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu index="1">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-user"></i>
              <!-- 文本 -->
              <span>会员管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/users" @click="saveNavState('/users')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-user-solid"></i>
                <!-- 文本 -->
                <span>会员列表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/usersrules" @click="saveNavState('/usersrules')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-user-solid"></i>
                <!-- 文本 -->
                <span>会员规则</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 一级菜单 -->
          <el-submenu index="2">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-goods"></i>
              <!-- 文本 -->
              <span>商品管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/goods" @click="saveNavState('/goods')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-s-goods"></i>
                <!-- 文本 -->
                <span>商品列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 一级菜单 -->
          <el-submenu index="3">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-tickets"></i>
              <!-- 文本 -->
              <span>订单管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/orders" @click="saveNavState('/orders')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-s-order"></i>
                <!-- 文本 -->
                <span>订单列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 一级菜单 -->
          <el-submenu index="4">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-office-building"></i>
              <!-- 文本 -->
              <span>配送管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/addressm" @click="saveNavState('/addressm')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-office-building"></i>
                <!-- 文本 -->
                <span>配送地址列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 一级菜单 -->
          <el-submenu index="5">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-setting"></i>
              <!-- 文本 -->
              <span>权限管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/admins" @click="saveNavState('/admins')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-s-tools"></i>
                <!-- 文本 -->
                <span>管理员列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 一级菜单 -->
          <el-submenu index="6">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-data-analysis"></i>
              <!-- 文本 -->
              <span>数据报表</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="/sales" @click="saveNavState('/sales')">
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-data-analysis"></i>
                <!-- 文本 -->
                <span>销售数据</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主题 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data: () => {
    return {
      userInfo: [],
      isCollapse: false,
      //被激活的链接
      activePath: ''
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const { data: res } = await this.$http.get('/admmy/admininfo')
      if (res.status !== 0) return this.$message.error(res.message)
      this.userInfo = res.message.data
    },
    logout() {
      //清空token
      window.sessionStorage.clear()
      //跳转登录页
      this.$router.push('/login')
    },
    //点击按钮折叠展开菜单
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #242424;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
  .logo {
    border-radius: 50%;
    margin-left: 15px;
    height: 50px;
    width: 50px;
  }
}
.el-aside {
  background-color: #545c64;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #dddddd;
}
.toggle-button {
  background-color: #494d61;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.el-tag{
  margin-right: 15px;
}
</style>