<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入用户名查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getOrderList"></el-button>
            </el-input>
          </el-col>
        </el-row>

        <!-- 用户列表区域 -->
        <el-table :data="this.orderlist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="用户名" prop="username"></el-table-column>
          <el-table-column label="商品名" prop="goodsname"></el-table-column>
          <el-table-column label="数量" prop="number"></el-table-column>
          <el-table-column label="商品价格" prop="price"></el-table-column>
          <el-table-column label="下单日期" prop="date"></el-table-column>
          <el-table-column label="真实姓名" prop="realname"></el-table-column>
          <el-table-column label="电话号码" prop="phone"></el-table-column>
          <el-table-column label="城市" prop="address"></el-table-column>
          <el-table-column label="地址" prop="address2"></el-table-column>
          <el-table-column label="支付状态" width="100px">
          <template v-slot="scope">
            <el-tag type="success" effect="dark">{{getpState(scope.row)}}</el-tag>
          </template>
        </el-table-column>
          <el-table-column label="状态" width="100px">
          <template v-slot="scope">
            <el-tag type="success" effect="dark">{{getState(scope.row)}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="190px">
          <template v-slot="scope">
            <el-button
              type="primary"
              @click="sendGoods(scope.row.id)"
              :disabled="sendGoodsBtn(scope.row.dstate)"
            >发货</el-button>
            <el-button
              type="primary"
              @click="sendGoodsOver(scope.row.id)"
              :disabled="sendGoodsOverBtn(scope.row.dstate)"
            >已送达</el-button>
          </template>
        </el-table-column>
        </el-table>
     
      </el-card>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    
    return {
      //获取用户列表的参数
      queryInfo: {
        query: '',

      },
      orderlist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
    }
  },
  created() {
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      const { data: res } = await this.$http.get('/adm/orderinfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.orderlist = res.message.data
      this.total = res.message.data.length
      console.log(this.orderlist);
    },

    getpState(items) {
      if (items.pstate === 1) {
        return '未支付'
      }
      if (items.pstate === 0) {
        return '支付成功'
      }
    },

    getState(items) {
      if (items.dstate === 2) {
        return '待发货'
      }
      if (items.dstate === 1) {
        return '配送中'
      }
      if (items.dstate === 0) {
        return '已送达'
      }
      if (items.dstate === -1) {
        return '订单已完成'
      }
      if (items.dstate === -2) {
        return '订单已取消'
      }
      if (items.dstate === -3) {
        return '订单已评论'
      }
    },
    async sendGoods(orderid) {

      const confirmResult = await this.$confirm(
        '确认发货吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消发货'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res1 } = await this.$http.put('/adm/updateorder', {
          id: orderid,
          dstate: 1
        })
        if (res1.status !== 0) return this.$message.error(res1.message)
        this.$message.success('更新信息成功！')
        this.getOrderList()
      }
    },
    sendGoodsBtn(dstate) {
      if (dstate !== 2) {
        return true
      } else {
        return false
      }
    },
    async sendGoodsOver(orderid){

      const confirmResult = await this.$confirm(
        '确认送达了吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res1 } = await this.$http.put('/adm/updateorder', {
          id: orderid,
          dstate: 0
        })
        if (res1.status !== 0) return this.$message.error(res1.message)
        this.$message.success('更新信息成功！')
        this.getOrderList()
      }
    },
    sendGoodsOverBtn(dstate) {
      if (dstate !== 1) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>