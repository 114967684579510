<template>
  <div>
    <h3>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>配送管理</el-breadcrumb-item>
        <el-breadcrumb-item>配送地址列表</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图区域 -->
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="请输入城市名名查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getCityList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true">添加城市名</el-button>
          </el-col>
        </el-row>

        <!-- 城市名列表区域 -->
        <el-table :data="this.citylist" border stripe>
          <el-table-column :label="this.total" type="index"></el-table-column>
          <el-table-column label="城市名" prop="city"></el-table-column>
          
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <!-- 删除按钮 -->
              <el-button
                class="button"
                type="danger"
                icon="el-icon-delete"
                size="big"
                @click="removeCityById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-card>
      <!-- 添加城市名 -->
      <el-dialog title="添加城市名" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
        <!-- 内容主题区域 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
          <el-form-item label="城市名" prop="city">
            <el-input v-model="addForm.city"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCity">确 定</el-button>
        </span>
      </el-dialog>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 2,
      },
      citylist: [],
      total: 0,
      //控制添加城市名对话框的显示与隐藏
      addDialogVisible: false,
      // 添加城市名的表单数据
      addForm: {
        city: '',
      },
      addFormRules: {
        city: [
          { required: true, message: '请输入城市名', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
      },
      editForm: {},
      editFormRules: {
        city: [
          { required: true, message: '请输入城市名', trigger: 'blur' },
          {
            min: 1,
            max: 45,
            message: '长度在 1 到 45 个字符',
            trigger: 'blur'
          }
        ],
      },
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {

      const { data: res } = await this.$http.get('/address/citylist', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.citylist = res.message.data
      this.total = res.message.data.length
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getCityList()
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage)
    },
    //监听添加城市名对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮添加新城市名
    addCity() {
      this.$refs.addFormRef.validate(async valid => {

        if (!valid) return
        const { data: res } = await this.$http.post('/address/upload', this.addForm)

        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        //隐藏对话框
        this.addDialogVisible = false
        //重新获取城市名列表
        this.getCityList()
      })
    },
    //根据ID删除对应城市名
    async removeCityById(goodsid) {
      //弹框提示城市名是否删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该城市名, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/goods/delete' ,{data:{id:goodsid}})
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getCityList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>