<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userindex' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>购物车</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getCartList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 商品列表区域 -->
      <el-table :data="this.cartlist" border stripe>
        <el-table-column :label="this.total" type="index"></el-table-column>
        <el-table-column label="商品图片">
          <template v-slot="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="GoodsImg(scope.row.goodsid)"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="商品名" prop="goodsname"></el-table-column>
        <el-table-column label="商品价格" prop="price"></el-table-column>
        <el-table-column label="商品数量" prop="number"></el-table-column>
        <el-table-column label="总价" prop="totalprice"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template v-slot="scope">
            <!-- 删除按钮 -->
            <el-button
              class="button"
              type="danger"
              icon="el-icon-delete"
              size="big"
              @click="removeGoodsById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-tag>购物车总价为：{{ this.tprice }}</el-tag>
      <el-button class="btn" type="success" plain @click="order"
        >下单</el-button
      >
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
      },
      queryInfo2: {
        query: '',
      },
      activeIndex: '4',
      cartlist: [],
      rulelist: [],
      total: 0,
      tprice: 0,
      discount: 0,
      apay: 0,
    }
  },
  created() {
    this.getUserInfo()
    window.sessionStorage.setItem('activeIndex', this.activeIndex)
  },
  methods: {
    //通过ID获取图片
    GoodsImg(id) {
      let goodsimg =
        'https://api.muaailgl.top/img/' + id + '.jpg?r=' + Math.random()

      return goodsimg
    },
    //查询用户信息
    async getUserInfo() {
      const { data: res } = await this.$http.get('/my/userinfo')
      if (res.status !== 0) return this.$message.error(res.message)
      this.userinfo = res.message.data

      this.getRulelist()
    },
    async getCartList() {
      const { data: res } = await this.$http.get('/cart/getcart', {
        params: this.queryInfo,
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.cartlist = res.message.data
      this.total = res.message.data.length
      this.tprice = res.message.tprice

      this.setRule()
    },
    //获取会员规则
    async getRulelist() {
      const { data: res } = await this.$http.get('/usersrules/rulelist', {
        params: this.queryInfo2,
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.rulelist = res.message.data

      this.getCartList()
    },
    //根据会员等级获取折扣力度
    async setRule() {
      for (let i = 0; i < this.rulelist.length; i++) {
        if (this.userinfo.level === this.rulelist[i].level) {
          this.discount = this.rulelist[i].discount
        }
      }

      this.tprice = this.tprice * this.discount

      this.tprice = this.tprice.toFixed(2)
    },
    async removeGoodsById(cartid) {
      //弹框提示商品是否删除
      const confirmResult = await this.$confirm(
        '此操作将会使该商品移出购物车, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        })
      })
      if (confirmResult === 'confirm') {
        const { data: res } = await this.$http.delete('/cart/deletecart', {
          data: { id: cartid },
        })
        if (res.status !== 0) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getCartList()
      }
    },
    async order() {
      const confirmResult = await this.$confirm(
        '请在个人中心确定您的信息是否正确,下单后无法修改和取消, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        })
      })
      if (confirmResult === 'confirm') {
        //扣除商品库存
        for (let i = 0; i < this.cartlist.length; i++) {
          const { data: res4 } = await this.$http.put('/goods/update', {
            goodsid: this.cartlist[i].goodsid,
            number: this.cartlist[i].number,
            ordersuccess: 1,
          })

          if (res4.status !== 0) return this.$message.error(res4.message)
        }
        //扣钱
        const { data: res3 } = await this.$http.put('/my/userinfo', {
          money: this.tprice,
          ordersuccess: 1,
        })
        if (res3.status !== 0) return this.$message.error(res3.message)

        //创建订单
        for (let i = 0; i < this.cartlist.length; i++) {
          this.apay =
            this.discount * this.cartlist[i].number * this.cartlist[i].price
          this.apay = parseFloat(this.apay).toFixed(2)
          //创建订单信息
          const { data: res } = await this.$http.post('/my/creatorder', {
            goodsname: this.cartlist[i].goodsname,
            number: this.cartlist[i].number,
            price: this.cartlist[i].price,
            actualpay: this.apay,
            pstate: 0,
          })
          if (res.status !== 0) return this.$message.error(res.message)

          //往销售数据表中插入数据
          const { data: res2 } = await this.$http.post('/sales/addsales', {
            goodsname: this.cartlist[i].goodsname,
            number: this.cartlist[i].number,
            actualpay: this.apay,
          })
          if (res2.status !== 0) return this.$message.error(res2.message)
        }

        this.$message.success('创建订单成功！')

        const { data: res2 } = await this.$http.delete('/cart/deletecart', {
          data: { deleteall: 1 },
        })
        if (res2.status !== 0) return this.$message.error(res2.message)
        // if (res.status !== 0) return this.$message.error(res.message)
        // this.$message.success(res.message)
        this.$router.push('/myorders')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
}
.el-tag {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>