import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import LoginUser from '@/components/LoginUser.vue'
import ReguserUser from '@/components/ReguserUser.vue'
import EditUserInfo from '@/components/EditUserInfo.vue'
import UserIndex from '@/components/UserIndex.vue'
import Home from '@/components/Home.vue'
import Welcome from '@/components/Welcome.vue'
import Users from '@/components/user/Users.vue'
import UsersRules from '@/components/user/UsersRules.vue'
import Admins from '@/components/admins/Admins.vue'
import GoodsList from '@/components/goods/List.vue'
import GoodsInfo from '@/components/goods/GoodsInfo.vue'
import GoodsIndex from '@/components/goodsindex/GoodsIndex.vue'
import MyShoppingCart from '@/components/my/MyShoppingCart.vue'
import MyOrders from '@/components/my/MyOrders.vue'
import MyInfo from '@/components/my/MyInfo.vue'
import MyCollection from '@/components/my/MyCollection.vue'
import Orders from '@/components/order/Orders.vue'
import AddressM from '@/components/addressm/AddressM.vue'
import Sales from '@/components/sales/Sales.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/loginuser' },
  { path: '/login', component: Login },
  { path: '/loginuser', component: LoginUser },
  { path: '/reguseruser', component: ReguserUser },
  { path: '/edituserinfo', component: EditUserInfo },
  {
    path: '/userindex', component: UserIndex,
    redirect: '/goodsindex',
    children: [
      { path: '/goodsindex', component: GoodsIndex },
      { path: '/myshoppingcart', component: MyShoppingCart },
      { path: '/myorders', component: MyOrders },
      { path: '/myinfo', component: MyInfo },
      { path: '/mycollection', component: MyCollection },
      { path: '/goodsinfo', component: GoodsInfo },
    ]
  },
  {
    path: '/home', component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/users', component: Users },
      { path: '/usersrules', component: UsersRules },
      { path: '/admins', component: Admins },
      { path: '/goods', component: GoodsList },
      { path: '/orders', component: Orders },
      { path: '/addressm', component: AddressM },
      { path: '/sales', component: Sales },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


//挂在路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从那个路径跳转来
  // next 是一个函数
  // next()放行 next('/login')强制跳转
  if (to.path === '/login') return next()
  if (to.path === '/loginuser') return next()
  if (to.path === '/reguseruser') return next()

  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/loginuser')
  next()
})

export default router
