<template>
  <el-container class="home-container">
    <el-header>
      <div class="logolabel">
        <img src="@/assets/logo.png" alt class="logo" />
        <span>果果生鲜平台</span>
      </div>
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleSelect"
        background-color="#242424"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="1" @click="index">首页</el-menu-item>
        <el-menu-item index="2" @click="myInfo">个人中心</el-menu-item>
        <el-menu-item index="3" @click="collection">我的收藏</el-menu-item>
        <el-menu-item index="4" @click="shoppingCart">购物车</el-menu-item>
        <el-menu-item index="5" @click="orders">我的订单</el-menu-item>
      </el-menu>
      <div class="logout">
        <el-tag type="danger">钱包：￥{{this.userinfo.money}}</el-tag>
        <el-tag type="success" effect="dark">会员等级：{{this.userinfo.level}} 折扣：{{this.discount}}</el-tag>
        <el-button size="small" type="info" @click="logout">登出</el-button>
      </div>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeIndex:'',
      discount:0,
      userinfo: [],
      rulelist: [],
      total: '',
      queryInfo: {
        query: ''
      }
    }
  },
  created() {
    this.activeIndex=window.sessionStorage.getItem('activeIndex')
    this.getUserInfo()
  },
  methods: {
    
    logout() {
      //清空token
      window.sessionStorage.clear()
      //跳转登录页
      this.$router.push('/loginuser')
    },
    //查询用户信息
    async getUserInfo() {
      const { data: res } = await this.$http.get('/my/userinfo')
      if (res.status !== 0) return this.$message.error(res.message)
      this.userinfo = res.message.data

      this.getRulelist()
    },
    //获取会员规则
    async getRulelist() {
      const { data: res } = await this.$http.get('/usersrules/rulelist', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.rulelist = res.message.data
      this.total = res.message.data.length
      this.setRule()
    },
    //根据消费金额设置会员等级
    async setRule() {
      for (let i = 0; i < this.rulelist.length; i++) {
        if (this.userinfo.totalcost > this.rulelist[i].totalcost) {
          this.userinfo.level = this.rulelist[i].level
          this.discount = this.rulelist[i].discount
        }
      }
      this.discount = (this.discount*100).toFixed(0)+"折"
      const { data: res } = await this.$http.put('/my/userinfo', {
        level: this.userinfo.level
      })
      if (res.status !== 0) return this.$message.error(res.message)
    },
    index() {
      //跳转首页
      this.$router.push('/userindex')
      this.getUserInfo()
    },
    myInfo() {
      //跳转我的信息
      this.$router.push('/myinfo')
      this.getUserInfo()
    },
    collection() {
      //跳转我的收藏
      this.$router.push('/mycollection')
      this.getUserInfo()
    },
    shoppingCart() {
      //跳转我的购物车
      this.$router.push('/myshoppingcart')
      this.getUserInfo()
    },
    orders() {
      //跳转我的订单
      this.$router.push('/myorders')
      this.getUserInfo()
    },
    handleSelect(key, keyPath) {}
  }
}
</script>

<style lang="less" scoped>
.home-container {
  width:100%;
  height: 100%;
}
.el-header {
  background-color: #242424;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  .logo {
    border-radius: 50%;
    margin-left: 15px;
    height: 50px;
    width: 50px;
  }
  .button {
    width: 100px;
    justify-content: flex-end;
  }
}
.logolabel {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
  }
}
.logout {
  float: right;
  .el-tag {
    margin-right: 15px;
  }
}
.el-main {
  background-color: #c9f3dc;
  width: 100%;
  height: 100%;
}
</style>