<template>
  <div>
    <Zmd></Zmd>
    <el-card style=" margin-top: 30px; background-color: #ffffff;">
      <div>
        <el-row :gutter="20" style=" margin-top: 15px; margin-left: 5px;">
          <el-col :span="7">
            <el-input placeholder="请输入商品名查询" v-model="queryInfo.query">
              <el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            v-for="(items,index) in goodslist"
            :key="index"
            v-if="items.status !== 1"
          >
            <el-card class="showgoods">
              <img :src="GoodsImg(items.id)" class="image2" />
              <div class="goodsInfo">
                <span class="namefont">商品名：{{items.goodsname}}</span>
                <div>
                  <el-button type="primary" round @click="checkInfo(items)">查看详情</el-button>
                  <el-button
                    class="collection"
                    type="warning"
                    icon="el-icon-star-off"
                    circle
                    @click="addCollection(items)"
                  ></el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import Zmd from '../zmd.vue'
export default {
  components: {
    Zmd
  },
  data() {
    return {
      activeIndex: '1',
      queryInfo: {
        query: ''
      },
      goodslist: [],
      total: 0
    }
  },
  created() {
    this.getGoodsList()
    window.sessionStorage.setItem('activeIndex', this.activeIndex)
  },
  methods: {
    //通过ID获取图片
    GoodsImg(id) {
      let goodsimg =
        'https://api.muaailgl.top/img/' + id + '.jpg?r=' + Math.random()

      return goodsimg
    },

    async getGoodsList() {
      const { data: res } = await this.$http.get('/list2/goodsinfo', {
        params: this.queryInfo
      })
      if (res.status !== 0) return this.$message.error(res.message)

      this.goodslist = res.message.data
      this.total = res.message.data.length
    },
    checkInfo(items) {
      // 把商品id保存到seesion中
      window.sessionStorage.setItem('id', items.id)
      //  保存商品名
      window.sessionStorage.setItem('goodsname', items.goodsname)
      this.$router.push('/goodsinfo')
    },
    async addCollection(items) {
      const { data: res } = await this.$http.post('/collection/addanddeletec', {
        goodsid: items.id,
        goodsname: items.goodsname,
        price: items.price
      })
      if (res.status !== 0) return this.$message.error(res.message)
      this.$message.success(res.message)
    }
  }
}
</script>

<style lang="less" scoped>
.namefont {
  font-size: 15px;
}
.showgoods {
  margin: 15px;
}
.goodsInfo {
  padding: 1px;
  line-height: 30px;
}
.collection {
  float: right;
}
.image2 {
  height: 100%;
  width: 100%;
  background-color: #e2f9fd;
}
</style>